import Link from "next/link";

export default function Custom404() {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-5 px-4 pt-24 pb-28 md:px-8 lg:-mt-32 lg:px-20 lg:pt-56 xl:px-24 2xl:px-32">
      <img
        src="/images/404.svg"
        alt="error"
        className="items-center justify-center"
      />
      <div className="flex flex-col items-center justify-center gap-5">
        <p className="font-nunito text-5xl font-bold text-[#142D59]">
          Halaman tidak ditemukan
        </p>
        <Link href="/">
          <button className="h-12 w-60 rounded-xl bg-[#142D59] font-nunito text-lg font-semibold text-white hover:opacity-80">
            Kembali ke Beranda
          </button>
        </Link>
      </div>
    </div>
  );
}
